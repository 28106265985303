import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-gtag';
import { EuiSpacer } from '@elastic/eui';
import TableFeatures from 'components/Indoor_Cameras/IN-8415_WQHD/Product_Features/TableFeatures';
import TableStatus from 'components/Indoor_Cameras/IN-8415_WQHD/Product_Features/TableStatus';
import NavButtons from 'components/Indoor_Cameras/IN-8415_WQHD/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "IN-8415 2k+ WQHD Manual Product Features",
  "path": "/Indoor_Cameras/IN-8415_WQHD/Product_Features/",
  "dateChanged": "2017-12-12",
  "author": "Mike Polinowski",
  "excerpt": "The IN-8415 2k+ WQHD is the first INSTAR camera with the new 1440p chipset.",
  "image": "./P_SearchThumb_IN-8415WQHD_Features.webp",
  "social": "/images/Search/P_SearchThumb_IN-8415WQHD_Features.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-IN-8415WQHD_white.webp",
  "chapter": "Indoor Cameras"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SEOHelmet title='IN-8415 2k+ WQHD Product Features' dateChanged='2017-12-12' author='Mike Polinowski' tag='INSTAR IP Camera' description='The IN-8415 2k+ WQHD is the first INSTAR camera with the new 1440p chipset.' image='/images/Search/P_SearchThumb_IN-8415WQHD_Features.webp' twitter='/images/Search/P_SearchThumb_IN-8415WQHD_Features.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Innenkameras/IN-8415_WQHD/Produkt_Features/' locationFR='/fr/Indoor_Cameras/IN-8415_WQHD/Product_Features/' crumbLabel="Features" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h2 {...{
      "id": "in-8415-2k-wqhd-manual",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#in-8415-2k-wqhd-manual",
        "aria-label": "in 8415 2k wqhd manual permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`IN-8415 2k+ WQHD Manual`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/4ec8a76606957adea4950114ae1b40b7/573d3/IN-8415_WQHD_Camera_Features.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "33.47826086956522%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAYAAAAIy204AAAACXBIWXMAAAsTAAALEwEAmpwYAAABNElEQVQoz01SCY6DMAzk/z/rD9rutioFqRUBcmBykTAre0XVkVBinBk74zR7zqgpIYWAbdtQa8U3OPbef77vPO9LKbJn7r7vaECE/nZD13VIKcFaKysnGTFGEWKs6yrxAeec8Iwx6PseIQQ0OxGMUliIkGIUwuo9yrIA1iJoDT9OrAw/TXIG3E0pmKcJj/sd7aP9CDdHu0f1lDMyE2IEF8tEoHlGsBY0jsgsGCNqjJiHAdP7DTPP0FpjGAY0XI2MwWotsg+IRFicE1+ZqNUI9XrBDgqq62C1lv8lBJDW6NoW58tFxIgIzbYseD2fyOsqhzx3QoTK19Yaqu/xc73icj6j/f2F0VqK8ZW3lKWz0+kkKw+pKd6LL8F7xBCQU/qfXK1CYqPZG/bYOifx/vUKeHicP/AHR3UcYJ+cy9AAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4ec8a76606957adea4950114ae1b40b7/e4706/IN-8415_WQHD_Camera_Features.avif 230w", "/en/static/4ec8a76606957adea4950114ae1b40b7/d1af7/IN-8415_WQHD_Camera_Features.avif 460w", "/en/static/4ec8a76606957adea4950114ae1b40b7/7f308/IN-8415_WQHD_Camera_Features.avif 920w", "/en/static/4ec8a76606957adea4950114ae1b40b7/e1c99/IN-8415_WQHD_Camera_Features.avif 1380w", "/en/static/4ec8a76606957adea4950114ae1b40b7/e02f7/IN-8415_WQHD_Camera_Features.avif 1650w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/4ec8a76606957adea4950114ae1b40b7/a0b58/IN-8415_WQHD_Camera_Features.webp 230w", "/en/static/4ec8a76606957adea4950114ae1b40b7/bc10c/IN-8415_WQHD_Camera_Features.webp 460w", "/en/static/4ec8a76606957adea4950114ae1b40b7/966d8/IN-8415_WQHD_Camera_Features.webp 920w", "/en/static/4ec8a76606957adea4950114ae1b40b7/445df/IN-8415_WQHD_Camera_Features.webp 1380w", "/en/static/4ec8a76606957adea4950114ae1b40b7/44758/IN-8415_WQHD_Camera_Features.webp 1650w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4ec8a76606957adea4950114ae1b40b7/81c8e/IN-8415_WQHD_Camera_Features.png 230w", "/en/static/4ec8a76606957adea4950114ae1b40b7/08a84/IN-8415_WQHD_Camera_Features.png 460w", "/en/static/4ec8a76606957adea4950114ae1b40b7/c0255/IN-8415_WQHD_Camera_Features.png 920w", "/en/static/4ec8a76606957adea4950114ae1b40b7/b1001/IN-8415_WQHD_Camera_Features.png 1380w", "/en/static/4ec8a76606957adea4950114ae1b40b7/573d3/IN-8415_WQHD_Camera_Features.png 1650w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/4ec8a76606957adea4950114ae1b40b7/c0255/IN-8415_WQHD_Camera_Features.png",
              "alt": "IN-8415 Full HD Produkt Features",
              "title": "IN-8415 Full HD Produkt Features",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <TableFeatures mdxType="TableFeatures" /> 
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`The IN-8415 2k+ WQHD is the newest WQHD PIR Indoor IP Camera from INSTAR. With a built-in passive infrared (PIR) motion sensor and four separately configurable alarm areas that can be linked together, the camera prevents false alarms and notifies you only when a real alarm has been triggered.`}</p>
    <p>{`Equipped with one of the latest SONY STARVIS 1440p CMOS image sensor, which features low noise, high speed and exceptional low-light capabilities, the IN-8415 2k+ WQHD is able to achieve the best image quality in daylight as well as clear image in night vision mode with the help of its LEDs 850nm (light red glow) wavelength emissions. `}</p>
    <p>{`This camera is running an embedded Linux operating system and a Webserver which supports TLS/SSL encryption for security and privacy. It provides a built-in, modern HTML5 based Web User Interface (WebUI) with focus on usability and simplicity, supporting all recent devices (desktop, tablet, smartphone) and operating systems (Windows, MacOS, Linux,…). Thanks to the built-in Camera Setup Wizard and the (built-in) help function, INSTAR users can enjoy a very easy initial set-up of the IP camera.`}</p>
    <h3 {...{
      "id": "status-leds",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#status-leds",
        "aria-label": "status leds permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Status LEDs`}</h3>
    <TableStatus mdxType="TableStatus" />
    <EuiSpacer mdxType="EuiSpacer" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      